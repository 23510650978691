import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import $ from 'jquery'
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

const About = () => {
  
  const [data, setData] = useState(null);

  useEffect(() => {
    $('.abt-txt').html($('.abt-txt div').text())
  })

  useEffect(() => {
    AboutCMS();
  }, [])

  const AboutCMS = async () => {
    await axios.get('https://roughradio.live/admin/api/about-us').then((res) => {
      setData(res.data);
    })
  }

  if (data == null) {
    return <div className='Loading'>Loading...</div>
  }

  console.log(data)

  return (
    <section className="about-sc">
      <div className="container-fluid ps-5 pe-5">
        <div className="col-12">
          <div className="un-head wow bounceInUp" style={{ visibility: 'visible', animationName: 'bounceInUp' }}>
            <h3>About Us</h3>
          </div>
        </div>
      </div>
      <div className="container-fluid ps-5 pe-5 wow bounceInUp" style={{ visibility: 'visible', animationName: 'bounceInUp' }}>
        <div className="scnd-cntnr overflow-hidden ">
          <div className="row">
            <div className="col-md-12 col-lg-7">
              <div className="abt-txt bg-[#fff]">
                {
                  renderHTML(data.sec46.cms_page_content)
                }
              </div>
            </div>
            <div className="col-md-12 col-lg-5">
              <div className="abt-txt-img new_abt_page_img">
                {/*<img src="https://www.roughradio.live/admin/assets/uploads/cms_page/0.43967600 1684328014_about-img.png">*/}
                <img src="https://www.roughradio.live/admin/assets/front_assets/images/new_star.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default About