import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery';
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

const Header = () => {

    const [data, SetData] = useState(null)

    const NextData = () => {
        axios.get(`https://roughradio.live/admin/api/home/ajax_data_get`).then((res) => {
            SetData(res.data)
        })
    }

    useEffect(() => {
        $('#audio-control').unbind().click(function () {
            if ($("#audio").prop('muted')) {
                $('#audio').get(0).play();
                $("#audio").prop('muted', false);
                $(this).html('<i class="fas fa-pause" aria-hidden="true"></i>');
                $('.rhap_play-status--playing .rhap_play-pause-button').click();
            } else {
                $("#audio").prop('muted', true);
                $(this).html('<i class="fas fa-play" aria-hidden="true"></i>');
            }
        });
        $(".ham-bar").click(function () {
            $("body").addClass("mb-menu-active")
        })
        $("a").click(function () {
            $("body").removeClass("mb-menu-active")
        })
        $(".mb-hdr-head .cross").click(function () {
            $("body").removeClass("mb-menu-active")
        })

    })

    // function GetTime() {
    //     var d = new Date()
    //     let h = d.getHours();
    //     let m = d.getMinutes();
    //     if (h < 10) { h = '0' + h }
    //     if (m < 10) { m = '0' + m }
    //     return h + ':' + m
    // }
    // console.log(GetTime())
    useEffect(() => {
        NextData();
        setInterval(function () {
            NextData();
        }, 10000)
    }, [])

    return (
        <div>
            <div className="mobile-headr ">
                <div className="mb-hdr-head">
                    <ul>
                        <li className="cross"><i className="fal fa-times" aria-hidden="true" /></li>
                        <li>
                            <Link to="/"><img src="https://www.roughradio.live/admin/assets/uploads/logo/fav-icn168433049410.png" alt="images" /></Link>
                        </li>
                        <li>
                            <div className="onair">
                                <span className="blink" />
                                <p>On Air</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="mb-hdr-bdy">
                    <ul className="mb-menu">
                        <li><Link to="/schedule">Schedule</Link></li>
                        <li><Link to="/resident"> Residents</Link></li>
                        <li><Link to="/event">Events</Link></li>
                        <li><Link to="/about-us">About</Link></li>
                        {/*<li><Link to="/application-form">Support us</Link></li>*/}
                        <li><Link to="/support-us">Support us</Link></li>
                        <li><Link to="/application-form">Apply</Link></li>
                    </ul>
                </div>
            </div>
            <header>
                <div className="menuSec d-none d-md-block">
                    <div className="container-fluid  ">
                        <div className="row">
                            <div className="col-md-12  col-lg-4 col-xl-3 hd-col-1">
                                <div className="header-logo">
                                    <Link to="/">
                                        <img src="https://www.roughradio.live/admin/assets/uploads/logo/fav-icn168433049410.png" className="fv-icn" alt="images" />
                                        <img src="https://www.roughradio.live/admin/assets/uploads/logo/logo168433049420.png" className="logo" alt="img" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-5 hd-col-2 ">
                                <ul className="menu-ul">
                                    <li><Link to="/schedule">Schedule</Link></li>
                                    <li><Link to="/resident"> Residents</Link></li>
                                    <li><Link to="/event">Events</Link></li>
                                    <li><Link to="/about-us">About</Link></li>
                                    {/*<li><Link to="/application-form">Support us</Link></li>*/}
                                    <li><Link to="/support-us">Support us</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-lg-8 col-xl-4 hd-col-2  ">
                                <div className="header-rgt">
                                    {data == null ? <div className='loadin'></div> :
                                        <div className="arrow-new">
                                            <ul>
                                                <li><span>Next</span></li>
                                                <li><img src="https://www.roughradio.live/admin/assets/front_assets/images/Arrow-long.png" alt="images" /></li>
                                                <li className="full_text">
                                                    <h6 className="grf-nexttrack">{renderHTML(data.next_track)}</h6>
                                                    <p className="grf-nexttrack_show">{data.next_track_title}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                    <div className="onair">
                                        <span className="blink" />
                                        <p>On Air</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-menu-br d-sm-block d-md-none">
                    <ul>
                        <li className="ham-bar"><i className="fas fa-bars" aria-hidden="true" /></li>
                        <li>
                            <Link to="/"><img src="https://www.roughradio.live/admin/assets/uploads/logo/fav-icn168433049410.png" alt="images" /></Link>
                        </li>
                        <li>
                            <div className="onair">
                                <span className="blink" />
                                <p>On Air</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="marque-sc">
                    <div className="container-fluid ">
                        <div className="marque-inner">
                            <div className="vid-abs">
                                <a href="javascript:void(0)" tabIndex={0}>
                                    <div id="audio-control" className="muted"><i className="fas fa-play" aria-hidden="true" /></div>
                                </a>
                                <audio muted id="audio">
                                    <source src="https://roughradio1.out.airtime.pro/roughradio1_a" type="audio/mp3" />
                                    <source src="https://roughradio1.out.airtime.pro/roughradio1_a" type="audio/mp3" />
                                    Your browser does not support the audio element.
                                </audio>
                                {/*<a href="https://prophetmanninghelp.com/storage/images/slider/“All households must keep a bottle of oil in their homes at all times”- Prophet Manning.mp4_1690443585.mp4" data-fancybox=""><i class="fas fa-play"></i></a>*/}
                            </div>
                            {data == null ? <div className='loadin'></div> : <marquee className="marq-txt-inn" scrolldelay={90} scrollamount={3}>{renderHTML(data.track_data)}</marquee>}
                            {/*<div class="marq-txt">*/}
                            {/*    <div class=""></div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </header>
            <Link to="/application-form" class="appyly-btn">Apply</Link>

        </div>
    )
}

export default Header
