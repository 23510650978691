import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const Events = () => {

  const [data, setData] = useState(null);
  useEffect(() => {
    GetData();
  }, [])

  const GetData = () => {
    axios.get(`https://roughradio.live/admin/api/event`).then((res) => {
      setData(res.data.data);
    });
  }

  if (data == null) {
    return <div className='Loading'></div>
  }

  return (
    <section className="event-pg-sc">
      <div className="container-fluid ps-5 pe-5">
        <div className="row">
          <div className="col-md-12    ">
            <div className="shedule-head">
              <div className="un-head wow bounceInUp">
                <h3>EVENT</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row wow slideInLeft">
          {
            data.map((event) => {
              return <div className="col-md-6 col-lg-4">
                <div className="event-inn-mn">
                  <Link to={`/event-detail/${event.event_slug}`}>
                    <img src={`https://www.roughradio.live/admin/assets/uploads/banner/${event.event_image}`} alt="images" />
                  </Link>
                  <div className="event-txt">
                    <Link to={`/event-detail/${event.event_slug}`}>
                      <h6>{event.event_name}</h6>
                    </Link>
                    <em>{event.event_short_desc}</em>
                  </div>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </section>

  )
}

export default Events