import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Notfound from './pages/Notfound'
import Home from './pages/Home';
import Header from './components/Header';
import ScrollTop from './components/ScrollTop';
import Schedule from './pages/Schedule';
import Residents from './pages/Residents';
import Events from './pages/Events';
import About from './pages/About';
import SupportUs from './pages/SupportUs';
import Apply from './pages/Apply';
import Event_Detail from './pages/Event_Detail';
import Genre_Sort from './pages/Genre_Sort';
import Resident_Detail from './pages/Resident_Detail';
import Player from './components/Player';
import { useEffect } from 'react';


function App() {

    useEffect(() => {
        localStorage.removeItem("audio_data");
    },[])

    return (
        <>
            <BrowserRouter>
                <Header />
                <Player />
                <ScrollTop />
                <Routes>
                    <Route path="/*" element={<Notfound />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/schedule" element={<Schedule />} />
                    <Route path="/resident" element={<Residents />} />
                    <Route path="/resident/:gen" element={<Genre_Sort />} />
                    <Route path="/resident-detail/:id" element={<Resident_Detail />} />
                    <Route path="/event" element={<Events />} />
                    <Route path="/about-us" element={<About />} />
                    <Route path="/support-us" element={<SupportUs />} />
                    <Route path="/application-form" element={<Apply />} />
                    <Route path="/event-detail/:slug" element={<Event_Detail />} />
                </Routes>
            </BrowserRouter>
        </>

    );
}

export default App;