import React from 'react'

const SupportUs = () => {
  return (
    <section className="application-frm">
      <div className="container">
        <div className="col-12">
          <div className="un-head wow bounceInUp" style={{ visibility: 'visible', animationName: 'bounceInUp' }}>
            <h3>Support Us</h3>
          </div>
          <div className="app-form-dv">
            <ul className="support_logos">
              <li><a href="https://www.patreon.com/roughradio?l=it" target="_blank" rel="nofollow"><img src="https://demo-customlinks.com/roughradio-dev/assets/front_assets/images/Patreon-logo.png" /></a></li>
              <li>OR</li>
              <li>
                <div className="paypal-form">
                  <form id="payment" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                    <input type="hidden" name="cmd" defaultValue="_donations" />
                    <input type="hidden" name="upload" defaultValue={1} />
                    <input type="hidden" name="business" defaultValue="info@roughradio.live" />
                    <input type="hidden" name="currency_code" defaultValue="USD" />
                    <input type="hidden" defaultValue name="return" id="paypal_return" />
                    <input type="hidden" defaultValue name="notify_url" id="paypal_notify_url" />
                    <input type="hidden" defaultValue name="cancel_return" id="paypal_cancel_return" />
                    <input type="image" src="https://demo-customlinks.com/roughradio-dev/assets/front_assets/images/paypal.png" name="submit" style={{ display: 'table', margin: '25px auto', width: '50%' }} title="Pay Now" />
                  </form>
                </div>
                {/*<a href="#"><img src="https://demo-customlinks.com/roughradio-dev/assets/front_assets/images/paypal.png"/></a>*/}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

  )
}

export default SupportUs