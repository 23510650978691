import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import $ from 'jquery'
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

const Event_Detail = () => {


    const { slug } = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        GetData();
    }, [])

    useEffect(() => {
        $('.desc-cts>div').html($('.desc-cts>div').text())
    })

    const GetData = () => {
        axios.get(`https://roughradio.live/admin/api/event-detail/${slug}`).then((res) => {
            setData(res.data.data);
        });
    }

    if (data == null) {
        return <div className='Loading'></div>
    }

    return (
        <section className="resident-dtl">
            <div className="container">
                <div className="row">
                    <div className="col-md-12  ">
                        <div className="resident-dtl-cntnt">
                            <div className="un-head wow bounceInUp">
                                <h3>Event</h3>
                            </div>
                            <div className="evnt-singl">
                                <img src={`https://roughradio.live/admin/assets/uploads/banner/${data.event_image}`} className="wow bounceInLeft" alt="images" />
                            </div>
                            <div className="resident-dtl-txt  wow bounceInRight">
                                <em>{data.event_short_desc}</em>
                                <h2>{data.event_name}</h2>
                                <div className='desc-cts'>{renderHTML(data.event_description)}</div>
                            </div>
                            <Link to="/event"> <i className="fal fa-long-arrow-left" aria-hidden="true" /> Back to all events</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Event_Detail