import axios from 'axios';
import React from 'react'
import $ from 'jquery';

const Apply = () => {
  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    axios.post(`https://roughradio.live/admin/api/contact-us/ajax_application`, formData).then((res) => {
      if(res.data.status === true){
        $('.show-msga').text(res.data.txt);
        e.target.reset();
      }else{
        $('.show-msga').text('an error occurred');
      }
      $('.show-msga').css('display', 'flex');
      setTimeout(function(){
        $('.show-msga').hide();
      },3000)
    })
  }
  return (
    <section className="application-frm">
      <div className="container">
        <div className="col-12">
          <div className="un-head wow bounceInUp" style={{ visibility: 'visible', animationName: 'bounceInUp' }}>
            <h3>Application Form</h3>
          </div>
          <div className="app-form-dv">
            <div className='show-msga'></div>
            <form onSubmit={handleSubmit} id="form-application_form" encType="multipart/form-data">
              <div className="row">
                <div className="col-md-6">
                  <label> Email <span className="color-red">*</span> </label>
                  <input type="email" name="application[application_email]" required />
                </div>
                <div className="col-md-6">
                  <label> Name/Nickname: <span className="color-red">*</span> </label>
                  <input type="text" required name="application[application_name]" />
                </div>
                <div className="col-md-12">
                  <label> City: <span className="color-red">*</span> </label>
                  <input type="text" required name="application[application_city]" />
                </div>
                <div className="col-md-12">
                  <label> Which type of show are you applying for: <span className="color-red">*</span> </label>
                  <ul>
                    <li>
                      <input type="radio" id="rd1" name="application[application_show_type]" defaultValue="Morning | Live Show | Between 10:00-12:00" />
                      <label>Morning | Live Show | Between 10:00-12:00</label>
                    </li>
                    <li>
                      <input type="radio" id="rd1" name="application[application_show_type]" defaultValue="Afternoon | Live Show | Between 12:00-18:00" />
                      <label>Afternoon | Live Show | Between 12:00-18:00</label>
                    </li>
                    <li>
                      <input type="radio" id="rd1" name="application[application_show_type]" defaultValue="Evening | Live Show | Between 18:00-21:00" />
                      <label>Evening | Live Show | Between 18:00-21:00</label>
                    </li>
                    <li>
                      <input type="radio" id="rd1" name="application[application_show_type]" defaultValue="Late Evening | Pre Recorded | Between 21:00 +" />
                      <label>Late Evening | Pre Recorded | Between 21:00 +</label>
                    </li>
                    <li>
                      <input type="radio" id="rd1" name="application[application_show_type]" defaultValue={0} />
                      <label>Other</label>
                      <input type="text" name="other" />
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <label> Name of show: <span className="color-red">*</span> </label>
                  <input type="text" required name="application[application_show]" />
                </div>
                <div className="col-md-6">
                  <label> Genres: <span className="color-red">*</span> </label>
                  <input type="text" required name="application[application_genres]" />
                </div>
                <div className="col-md-12">
                  <label> How often would you want to host a show? <span className="color-red">*</span> </label>
                  <ul>
                    <li>
                      <input type="radio" id="rd2" name="application[application_duration]" defaultValue="Once a month" />
                      <label>Once a month</label>
                    </li>
                    <li>
                      <input type="radio" id="rd2" name="application[application_duration]" defaultValue="Once every 2 months" />
                      <label>Once every 2 months</label>
                    </li>
                    <li>
                      <input type="radio" id="rd2" name="application[application_duration]" defaultValue="One Off only" />
                      <label>One Off only</label>
                    </li>
                  </ul>
                </div>
                <div className="col-md-12">
                  <label> How would your show be presented?<span className="color-red">*</span> </label>
                  <ul>
                    <li>
                      <input type="radio" id="rd3" name="application[application_show_presented]" defaultValue="Live" />
                      <label>Live</label>
                    </li>
                    <li>
                      <input type="radio" id="rd3" name="application[application_show_presented]" defaultValue="Pre Recorded" />
                      <label>Pre Recorded</label>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <label>
                    Please provide any relevant social media links:
                  </label>
                  <input type="text" required name="application[application_socialmedia_link]" />
                </div>
                <div className="col-md-6">
                  <label>
                    Please explain the concept behind your show:
                  </label>
                  <input type="text" required name="application[application_show_concept]" />
                </div>
                <div className="col-md-12">
                  <label>
                    Please provide a link to a pre-recorded show or previous show that represents your show concept:
                  </label>
                  <input type="text" required name="application[application_link_pre_record]" />
                </div>
                <div className="col-md-12">
                  <label>
                    Provide a concept artwork to be used for socials and website:
                  </label>
                  <input type="file" name="application[application_file]" className="avatar-input" />
                  <sub style={{ color: 'red' }}>Allow file: png,jpeg,jpg</sub>
                </div>
                <button type="submit">submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Apply