import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import $ from 'jquery';
import axios from 'axios';
import { Link } from 'react-router-dom';
import EmojiPicker, { EmojiStyle, Theme, EmojiClickData } from 'emoji-picker-react';
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

const Home = () => {

    const [data, setData] = useState(null);
    const [chatvlaue, setChatvlaue] = useState('');
    const [offset, setOffset] = useState(0);
    const [chat, setChat] = useState(null);
    const [GENID, setGENID] = useState(null);
    const [load, setLoad] = useState(null);
    const [socail, setSocail] = useState(null);

    const getSocail = async () => {
        await axios.get('https://www.roughradio.live/admin/api/home/config_setting').then((res) => {
            setSocail(res.data);
        })
    }

    useEffect(() => {
        getSocail()
    }, [])

    const getChatData = () => {
        $('.loadr').show();
        axios.get(`https://roughradio.live/admin/api/home/appendChat`, {
            params: {
                offset: offset,
            }
        }).then((res) => {
            $('.loadr').hide();
            if (chat === null) {
                setChat(res.data);
            } else {
                setChat(res.data + chat);
            }
            if ($('#focus_span').length) {
                $('#focus_span').remove();
            }
            if (res.data != '<span id="focus_span"></span>' && offset !== 0) {
                setTimeout(function () {
                    document.querySelector('#focus_span').scrollIntoView(false)
                }, 200)
            }
        })
    }

    const AddEmoji = (EmojiClickData) => {
        $('#editable').html($('#editable').html() + (EmojiClickData.isCustom ? EmojiClickData.unified : EmojiClickData.emoji))
        $('.emoji-pop').removeClass("active");
    }
    const setting = {
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        arrows: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const setting2 = {
        autoplay: false,
        dots: false,
        arrows: true,
        infinite: false,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1200,
            settings: {
                autoplay: true,
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
        ]
    }
    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return false;
    }
    function checkCookie() {
        let user = getCookie("roughradio-username");
        if (user != "") {
            alert("Welcome again " + user);
        } else {
            user = prompt("Please enter your chat username:", "");
            if (user != "" && user != null) {
                setCookie("roughradio-username", user, 30);
                setCookie("roughradio-usercolor", getColor(), 30);
                $('input[name=chat_sender]').val(user)
            } else {
                checkCookie()
            }
        }
    }
    useEffect(() => {
        if ($('.abt-txt div').text().includes('<')) {
            $('.abt-txt div').html($('.abt-txt div').text())
        }
    })
    useEffect(() => {
        $(".abt-txt-img").on("mousemove", function (ev) {
            var degY = 360 * (ev.pageX / $(document).width());
            var degX = 360 * (ev.pageY / $(document).height());
            $("#cube").css("transform", "  rotateY( " + degY + "deg) rotateX(" + degX + "deg)");

        });
        $(".abt-txt-img").mouseenter(function () {
            $("#cube").removeClass("aniactive");
        })
        $(".abt-txt-img").mouseleave(function () {
            $("#cube").addClass("aniactive");
        })
        $('.emoji').unbind().click(function () {
            $('.emoji-pop').toggleClass("active");
            // if($('.emoji-pop').hasClass("active")){
            //     setLoad(null)
            // }else{
            setLoad(1)
            // }
        });
        $('.chat-massg').scroll(function () {
            if ($(this).scrollTop() === 0) {
                setOffset(offset + 10);
            }
        })
        if (offset === 0) {
            $('.chat-massg').scrollTop($('.chat-massg').prop('scrollHeight'));
        }
        $('body').on('focus', '.editor', function (e) {
            e.preventDefault()
            if ($('input[name=chat_sender]').val() == '' && !getCookie("roughradio-username")) {
                checkCookie();
            } else {
                $('input[name=chat_sender]').val(getCookie("roughradio-username"))
            }
        })
        $('.chat-massg div[color]').each(function () {
            let clr = $(this).attr('color');
            if (clr !== '') {
                $(this).find('>span').css('color', clr);
            }
        })
        $('.user-tagger').html('');
        var seen = {};
        $('.chat-massg div[color]>span').each(function () {
            var txt = $(this).text().replace(/\s+/g, '').replace(':', '');
            if (seen[txt]) { }
            else {
                seen[txt] = true;
                $('.user-tagger').append("<span>@ " + txt + "</span>");
            }
        })
        $('.user-tagger>span').click(function () {
            let txt = $(this).text();
            let str = setCharAt($('.editor').html(), $('.editor').html().length - 1, '&#x200B<tagg contenteditable="false">' + txt + '</tagg>&#x200B');
            $('.editor').html(str);
            setCaret()
            $('.user-tagger').hide();
        })
        $(document).click(function (e) {
            if (e.target.id !== 'hashtag') {
                if ($('.user-tagger').is(':visible')) {
                    $('.user-tagger').hide();
                }
            }
        })

        $(document).ready(function () {
            setTimeout(function () {
                $('[d-src]').each(function () {
                    $(this).attr('src', $(this).attr('d-src'));
                })
            }, 2000)
        })
    })

    useEffect(() => {
        $('.editor').keydown(function (e) {
            $(this).find('br').remove();
            var keycode = (e.keyCode ? e.keyCode : e.which);
            if (keycode == '13') {
                $('.btn.clkkk').eq(0).click();
                return false;
            }
        })
    })

    function setCaret() {
        var el = document.getElementById("editable")
        var range = document.createRange()
        var sel = window.getSelection()
        console.log(el.childNodes)
        range.setStart(el.childNodes[el.childNodes.length - 1], 1);
        range.collapse(true)

        sel.removeAllRanges()
        sel.addRange(range)
    }

    function setCharAt(str, index, chr) {
        if (index > str.length - 1) return str;
        return str.substring(0, index) + chr + str.substring(index + 1);
    }
    function hslToHex(h, s, l) {
        l /= 100;
        const a = s * Math.min(l, 1 - l) / 100;
        const f = n => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    }
    function getColor() {
        return hslToHex(360 * Math.random(), (25 + 70 * Math.random()), (65 + 10 * Math.random()));
    }
    // console.log(getColor())
    let ghj = 0;
    const SendMessage = (e) => {
        e.preventDefault();
        if ($('.editor').html() !== '' && getCookie("roughradio-username") !== false && getCookie("roughradio-username") !== '' && ghj === 0) {
            ghj = 1;
            const datas = new FormData();
            datas.append('chat_sender', getCookie("roughradio-username"))
            datas.append('chat_color', getCookie("roughradio-usercolor"))
            datas.append('chat_ip', data.ip);
            // datas.append('chat_ip', ' 95.154.247.104');
            datas.append('chat_message', $('.editor').html())
            axios.post(`https://roughradio.live/admin/api/home/sendMessage`, datas).then((res) => {
                setChat(null);
                setOffset(0);
                $('.editor').html('');
                setChatvlaue('');
                setTimeout(function () {
                    ghj = 0;
                }, 1000)
            })
        }
    }

    useEffect(() => {
        getChatData();
    }, [offset])

    useEffect(() => {
        if (chat === null) {
            getChatData();
        }
    }, [chat])
    let chat_count = null;
    useEffect(() => {
        HomeCMS();
        setInterval(function () {
            axios.post(`https://roughradio.live/admin/api/home/chatMessageRefresh`, { chat_count: chat_count }).then((res) => {
                if (res.data.chat_count != chat_count) {
                    getChatData();
                }
                chat_count = res.data.chat_count;
            })
        }, 1000)
    }, [])

    useEffect(() => {
        $('#textarea').val(chatvlaue)
    }, [chatvlaue])

    const setVal = (e) => {
        if (e.target.innerText[e.target.innerText.length - 1] == '@') {
            $('.user-tagger').show();
        } else {
            $('.user-tagger').hide();
        }
    }

    const HomeCMS = async () => {
        await axios.get('https://roughradio.live/admin/api/home').then((res) => {
            setData(res.data);
            let genre_ID = {};
            res.data.artist_data.map((art) => {
                genre_id(art.artist_id).then((res) => {
                    genre_ID = { ...genre_ID, [art.artist_id]: res }
                    setGENID(genre_ID);
                });
            })
        })
    }

    function genre_id(id) {
        return axios.get(`https://roughradio.live/admin/api/home/genre_list/${id}`).then((res) => {
            return res.data[0];
        });
    }

    if (data == null || GENID == null || socail === null) {
        return <div className='Loading'>Loading...</div>
    }
    return (
        <div>
            <section className="mn-bannersc">
                <div className="container-fluid ps-5 pe-5">
                    <div className="row">
                        <div className="col-12 d-sm-block d-md-none">
                            <div className="un-head wow bounceInUp">
                                <h3>ROUGH RADIO</h3>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            {/* {console.log(data.video_streaming.live_video_link.replace(/&amp;/g, '&'))} */}
                            <div className="banner-img wow bounceInLeft">
                                {
                                    (() => {
                                        if (data.video_streaming.live_video_type === '1') {
                                            return <iframe width="560" height="315" src={data.video_streaming.live_video_link.replace(/&amp;/g, '&')} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        } else if (data.video_streaming.live_video_type === '3') {
                                            return <img src='images/loader.gif' d-src={`https://www.roughradio.live/admin/${data.video_streaming.live_video_video_path}/${data.video_streaming.live_video_image}`} alt={data.video_streaming.live_video_image} />
                                        } else {
                                            return <video
                                                src={`https://www.roughradio.live/admin/${data.video_streaming.live_video_video_path}/${data.video_streaming.live_video_video}`}
                                                autoPlay
                                                playsInline
                                                muted
                                                loop
                                                controls
                                                controlslist="nodownload"
                                                style={{ width: "100%", height: "100%" }}
                                            />
                                        }
                                    })()
                                }
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="banner-txt wow bounceInRight">
                                <h3>CHAT <div className='loadr'></div></h3>
                                <div className="chat-box">
                                    <div className="chat-massg">
                                        {chat === null ? <div className='Loading'></div> : renderHTML(chat)}
                                    </div>
                                    <input type="hidden" name="limit" defaultValue={10} />
                                    <input type="hidden" name="offset" defaultValue={10} />
                                    <input type="hidden" name="chat_count" defaultValue={26} />
                                    <form id="message_form">
                                        <div className="chat-cntrol">
                                            <input type="hidden" name="chat_sender" defaultValue="" />
                                            <ul>
                                                {data.chat_text_block !== false ?
                                                    <li className="li-second"><p className='blocked'>You are blocked by administrator</p></li>
                                                    :
                                                    <><li className="li-first">
                                                        <span id="hashtag" onClick={() => { $('.user-tagger').show() }}>@</span>
                                                    </li>
                                                        <li className="li-second">
                                                            <div className='user-tagger'></div>
                                                            {/* <input
                                                                type="text"
                                                                id="textarea"
                                                                name="chat_message"
                                                                maxLength={5000}
                                                                autoComplete="off"
                                                                onChange={setVal}
                                                            /> */}
                                                            <div className='editor' id='editable' suppressContentEditableWarning={true} contentEditable onInput={setVal} maxLength={5000}></div>

                                                        </li>
                                                        <li className="li-last">
                                                            <div className="emoji-pop">
                                                                {load !== null ? <EmojiPicker
                                                                    onEmojiClick={AddEmoji}
                                                                    emojiStyle={EmojiStyle.GOOGLE}
                                                                    theme={Theme.DARK}
                                                                /> : false}
                                                            </div>
                                                            <i className="fal fa-grin-alt emoji" aria-hidden="true" />
                                                            <button type="submit" onClick={SendMessage} className="btn clkkk">
                                                                <i className="fa fa-paper-plane" aria-hidden="true" />
                                                            </button>
                                                        </li></>
                                                }
                                            </ul>
                                        </div>
                                        <p id="files-area">
                                            <span id="videoList">
                                                <span id="video-names" />
                                            </span>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="resident-sec">
                <div className="container-fluid ps-5 pe-5">
                    <div className="col-12">
                        <div className="un-head wow bounceInUp">
                            <h3>RESIDENTS</h3>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ps-5 pe-5 resi-sl-cont">
                    <div className="ro">
                        <Slider className="residnt-sl-mn" {...setting}>
                            {
                                data !== null ? data.artist_data.map((artist) => {
                                    return <div>
                                        <div className="resi-inn artist_box">
                                            <div className="resi-img">
                                                <Link to={`/resident-detail/${artist.artist_id}`}>
                                                    <img src='images/loader.gif' d-src={`https://www.roughradio.live/admin/${artist.artist_image_path}${artist.artist_image}`} alt="images" />
                                                </Link>
                                            </div>
                                            <div className="artist_listss">
                                                <ul>
                                                    <li>
                                                        <Link to={`/resident-detail/${artist.artist_id}`}>
                                                            <h6>{artist.artist_name}</h6>
                                                        </Link>
                                                    </li>
                                                    <li className="meta-res genres_list">
                                                        <Slider className="tag-slider" {...setting2}>
                                                            {
                                                                GENID[artist.artist_id] !== undefined ? Object.keys(GENID[artist.artist_id]).map((gen) => {
                                                                    return <a href={`/resident/${gen}`}>
                                                                        {renderHTML(GENID[artist.artist_id][gen])}
                                                                    </a>
                                                                }) : false
                                                            }
                                                        </Slider>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }) : <div className='Loading'></div>
                            }

                        </Slider>
                    </div>
                </div>
            </section>
            <section className="about-sc">
                <div className="container-fluid ps-5 pe-5">
                    <div className="col-12">
                        <div className="un-head wow bounceInUp">
                            <h3>{data.sec51.cms_page_name}</h3>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ps-5 pe-5  wow bounceInUp">
                    <div className="scnd-cntnr overflow-hidden">
                        <div className="row">
                            <div className="col-md-12 col-lg-7">
                                <div className="abt-txt">
                                    {
                                        renderHTML(data.sec51.cms_page_content)
                                    }
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-5">
                                <div className="abt-txt-img">
                                </div>
                                <div className="abt-txt-img">
                                    <div className="new-container">
                                        <div id="cube" className="aniactive">
                                            <figure className="front">
                                                <img src='images/loader.gif' d-src={`https://www.roughradio.live/admin/${data.sec51.cms_page_image_path}${data.sec51.cms_page_image}`} />
                                            </figure>
                                            <figure className="back">
                                                <img src='images/loader.gif' d-src={`https://www.roughradio.live/admin/${data.sec51.cms_page_image_path}${data.sec51.cms_page_image}`} />
                                            </figure>
                                            <figure className="right">
                                                <img src='images/loader.gif' d-src={`https://www.roughradio.live/admin/${data.sec51.cms_page_image_path}${data.sec51.cms_page_image}`} />
                                            </figure>
                                            <figure className="left">
                                                <img src='images/loader.gif' d-src={`https://www.roughradio.live/admin/${data.sec51.cms_page_image_path}${data.sec51.cms_page_image}`} />
                                            </figure>
                                            <figure className="top">
                                                <img src='images/loader.gif' d-src={`https://www.roughradio.live/admin/${data.sec51.cms_page_image_path}${data.sec51.cms_page_image}`} />
                                            </figure>
                                            <figure className="bottom">
                                                <img src='images/loader.gif' d-src={`https://www.roughradio.live/admin/${data.sec51.cms_page_image_path}${data.sec51.cms_page_image}`} />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mearch-sec">
                <div className="container-fluid ps-5 pe-5">
                    <div className="row">
                        <div className="col-md-12 col-lg-6">
                            <div className="merch-img wow bounceInUp">
                                <h3>MERCH</h3>
                                <div className="merch-img-inn">
                                    <img src='images/loader.gif' d-src={`https://www.roughradio.live/admin/${data.sec52.cms_page_image_path}${data.sec52.cms_page_image}`} alt="images" />
                                    {
                                        renderHTML(data.sec52.cms_page_content)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="merch-img-txt wow bounceInUp" data-wow-delay=".4s">
                                <h3>PLATFORMS</h3>
                                <div className="merch-img-inn-txt">
                                    <ul className="logos">
                                        {
                                            socail.instagram_id === '' ? false : <li><a href={socail.instagram_id} target="_blank" rel="nofollow"><i className="fab fa-instagram" /></a></li>
                                        }
                                        {
                                            socail.soundcloud_id === '' ? false : <li><a href={socail.soundcloud_id} target="_blank" rel="nofollow"><i className="fab fa-soundcloud" /></a></li>
                                        }
                                        {
                                            socail.facebook_id === '' ? false : <li><a href={socail.facebook_id} target="_blank" rel="nofollow"><i className="fab fa-facebook" /></a></li>
                                        }
                                        {
                                            socail.voice_assistant_id === '' ? false : <li><a href={socail.voice_assistant_id} target="_blank" rel="nofollow"><img src='images/loader.gif' d-src="https://www.roughradio.live/admin/assets/front_assets/images/voice-assistant.png" /></a></li>
                                        }
                                    </ul>
                                    <ul className="dsgn">
                                        <li>
                                            <h6>DESIGN: <span>{socail.design_by}</span></h6>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}


export default Home
