import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import $ from 'jquery'
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

const Residents = () => {

  const navigate = useNavigate();
  const [opendrop, setOpen] = useState(false);
  useEffect(() => {
    // $('.select-dropdown__button').on('click', function () {
    //   $('.select-dropdown__list').toggleClass('active');
    // });
    $('.select-dropdown__list-item').on('click', function () {
      var itemValue = $(this).data('value');
      navigate(`/resident/${itemValue}`);
      $('.select-dropdown__button span').text($(this).text()).parent().attr('data-value', itemValue);
      $('.select-dropdown__list').toggleClass('active');
    });

    $('[data-sort]').click(function (e) {
      e.preventDefault();
      $('.resi-active').removeClass('resi-active');
      $(this).parent().addClass('resi-active');
      if ($(this).attr('data-sort') == 'null') {
        setCase(null);
      } else {
        setCase($(this).attr('data-sort'));
      }
    })
    $(document).ready(function () {
      setTimeout(function () {
        $('[d-src]').each(function () {
          $(this).attr('src', $(this).attr('d-src'));
        })
      }, 2000)
    })
  })
  const setting2 = {
    autoplay: false,
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 1200,
      settings: {
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
    ]
  }
  const [artist, setArtist] = useState(null);
  const [genre, setGenre] = useState(null);
  const [cases, setCase] = useState(null);
  const [GENID, setGENID] = useState(null);
  useEffect(() => {
    GetData();
    GetGenre();
  }, [])

  function genre_id(id) {
    return axios.get(`https://roughradio.live/admin/api/home/genre_list/${id}`).then((res) => {
      return res.data[0];
    });
  }

  const GetData = () => {
    axios.get(`https://roughradio.live/admin/api/resident`).then((res) => {
      setArtist(res.data.artist_data);
      let genre_ID = {};
      res.data.artist_data.map((art) => {
        genre_id(art.artist_id).then((res) => {
          genre_ID = { ...genre_ID, [art.artist_id]: res }
          setGENID(genre_ID);
        });
      })
    });
  }

  const GetGenre = () => {
    axios.get(`https://roughradio.live/admin/api/home/genre_list/`).then((res) => {
      setGenre(res.data[0]);
    });
  }




  if (artist == null || genre == null || GENID == null) {
    return <div className='Loading'></div>
  }
  return (
    <div>
      <section className="resident-sec resident-pg-sec">
        <div className="container-fluid ps-5 pe-5">
          <div className="row">
            <div className="col-12">
              <div className="un-head whclr wow bounceInUp">
                <h3 className="resident_heading">
                  <span>RESIDENTS</span>
                  <div className="select-dropdown">
                    <button
                      href="#"
                      role="button"
                      data-value
                      className="select-dropdown__button"
                      onClick={() => {
                        if (opendrop === false) {
                          setOpen(true)
                        } else {
                          setOpen(false)
                        }
                      }}
                    >
                      <span>Genre </span>
                      <i className="fas fa-chevron-down" />
                    </button>
                    <ul className={`select-dropdown__list ${opendrop === true ? 'active' : false}`}>
                      {
                        Object.keys(genre).sort(function(a, b) {
                          return genre[b].localeCompare(genre[a]);
                       }).map((gen) => {
                          return <li data-value={gen} className="select-dropdown__list-item">
                            {genre[gen]}
                          </li>
                        }).reverse()
                      }
                    </ul>
                  </div>
                </h3>
              </div>
              <div className="resi-fltr wow bounceInUp" style={{ visibility: "visible", animationName: "bounceInUp" }}>
                <ul>
                  <li>
                    <a data-sort="a">A</a>
                  </li>
                  <li>
                    <a data-sort="b">B</a>
                  </li>
                  <li>
                    <a data-sort="c">C</a>
                  </li>
                  <li>
                    <a data-sort="d">D</a>
                  </li>
                  <li>
                    <a data-sort="e">E</a>
                  </li>
                  <li>
                    <a data-sort="f">F</a>
                  </li>
                  <li>
                    <a data-sort="g">G</a>
                  </li>
                  <li>
                    <a data-sort="h">H</a>
                  </li>
                  <li>
                    <a data-sort="i">I</a>
                  </li>
                  <li>
                    <a data-sort="j">J</a>
                  </li>
                  <li>
                    <a data-sort="k">K</a>
                  </li>
                  <li>
                    <a data-sort="l">L</a>
                  </li>
                  <li>
                    <a data-sort="m">M</a>
                  </li>
                  <li>
                    <a data-sort="n">N</a>
                  </li>
                  <li>
                    <a data-sort="o">O</a>
                  </li>
                  <li>
                    <a data-sort="p">P</a>
                  </li>
                  <li>
                    <a data-sort="q">Q</a>
                  </li>
                  <li>
                    <a data-sort="r">R</a>
                  </li>
                  <li>
                    <a data-sort="s">S</a>
                  </li>
                  <li>
                    <a data-sort="t">T</a>
                  </li>
                  <li>
                    <a data-sort="u">U</a>
                  </li>
                  <li>
                    <a data-sort="v">V</a>
                  </li>
                  <li>
                    <a data-sort="w">W</a>
                  </li>
                  <li>
                    <a data-sort="x">X</a>
                  </li>
                  <li>
                    <a data-sort="y">Y</a>
                  </li>
                  <li>
                    <a data-sort="z">Z</a>
                  </li>
                  <li>
                    <a data-sort="null">#</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="resident-sec mar_resident">
        <div className="container-fluid ps-5 pe-5 resi-asl-cont">
          <div className="row wow bounceInLeft">
            <div className="residnt-sl-mna">
              <div className="row">
                {
                  artist.filter((arts) => {
                    if (cases != null) {
                      return arts.artist_name.slice()[0].toLowerCase() == cases;
                    } else {
                      return arts;
                    }
                  }).map((art) => {
                    return <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="resi-inn artist_box" style={{ 'margin-left': '0', 'margin-right': '0' }}>
                        <div className="resi-img">
                          <Link to={`/resident-detail/${art.artist_id}`}>
                            <img
                              src='images/loader.gif' d-src={`https://www.roughradio.live/admin/assets/uploads/banner/${art.artist_image}`}
                              alt="images"
                            />
                          </Link>
                        </div>
                        <div className="artist_listss">
                          <ul>
                            <li>
                              <Link to={`/resident-detail/${art.artist_id}`}>
                                <h6>{art.artist_name}</h6>
                              </Link>
                            </li>
                            <li className="meta-res genres_list">
                              <Slider className="tag-slider" {...setting2}>
                                {
                                  GENID[art.artist_id] !== undefined ? Object.keys(GENID[art.artist_id]).map((gen) => {
                                    return <a href={`/resident/${gen}`}>
                                      {renderHTML(GENID[art.artist_id][gen])}
                                    </a>
                                  }) : false
                                }
                              </Slider>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  })
                }
                <div className="notfound"><h3>Artist list is empty</h3></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
};

export default Residents;
