import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import $ from 'jquery';
import axios from "axios";
import Moment from 'react-moment';

const Schedule = () => {

  const [data, setData] = useState(null);
  // const [tmr, setTM] = useState([]);
  const sliderRef = useRef();
  const sliderRef2 = useRef();

  const sld1 = {
    dots: false,
    arrows: true,
    centerMode: true,
    centerPadding: "0px",
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
  };
  const sld2 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    fade: true,
    infinite: false,
    draggable: false,
  };

  useEffect(() => {
    $('.schedul-sldr .slick-next').click(function () {
      $('.schedule_table_slider .slick-next').click()
    })
    $('.schedul-sldr .slick-prev').click(function () {
      $('.schedule_table_slider .slick-prev').click()
    })
    $(document).ready(function () {
      let ind = $('[today="true"]').closest('.slick-slide').index();
      sliderRef.current.slickGoTo(ind);
      sliderRef2.current.slickGoTo(ind);
      console.log(ind)
    })
  })


  useEffect(() => {
    GetSchedule();
  }, [])


  const GetSchedule = async () => {
    await axios.get('https://roughradio.live/admin/api/schedule').then((res) => {
      setData(res.data.data);
    })
  }



  if (data == null) {
    return <div className='Loading'>Loading...</div>
  }
  else {
    let arr = [];
    let day = '';
    return (
      <section className="shedule-sec shedule-pg-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-7  ">
              <div className="shedule-head wow slideInLeft">
                <div className="un-head">
                  <h3>SCHEDULE</h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-5 ">
              <Slider ref={sliderRef2} className="schedul-sldr wow slideInRight" {...sld1}>

                {

                  (() => {
                    try {
                      let index;
                      Object.keys(data).forEach(function (key) {
                        var value = data[key];
                        if (key != "AIRTIME_API_VERSION") {
                          if (value.length != 0) {
                            arr.push({ day: key, date: value });
                          } else {
                            if (arr[arr.length - 1].date[0].start_timestamp == undefined) {
                              var date = new Date(arr[arr.length - 1].date[0]);
                            } else {
                              var date = new Date(arr[arr.length - 1].date[0].start_timestamp);
                            }
                            date.setDate(date.getDate() + 1);
                            arr.push({ day: key, date: [date, 'value'] });
                          }
                        }
                        index = key;
                      })

                      const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                      const d = new Date();
                      day = weekday[d.getDay()];

                    } catch (err) {
                      console.log(err)
                    }
                  })()
                }
                {
                  arr.map((sch) => {
                    if (sch.date[1] != 'value') {
                      return <div className={sch.day}>
                        <h3 today={sch.day === day.toLowerCase() ? 'true' : 'false'}><Moment format="MMM DD/YY">{sch.date[0].start_timestamp}</Moment> ({sch.day})</h3>
                      </div>
                    } else {
                      return <div className={sch.day}>
                        <h3 today={sch.day === day.toLowerCase() ? 'true' : 'false'}><Moment format="MMM DD/YY">{sch.date[0]}</Moment> ({sch.day})</h3>
                      </div>
                    }
                  })
                }
              </Slider>
            </div>
          </div>
          <div className="col-12">
            <Slider ref={sliderRef} className="schedule_table_slider" {...sld2}>
              {
                arr.map((sch) => {
                  return <div className="schedul-list wow bounceInUp" data-wow-delay=".4s">
                    <ul>
                      {
                        sch.date[1] != 'value' ? sch.date.map((item) => {
                          if (item.name.toLowerCase() !== "picks from the archive") {
                            return <li>
                              <h6>{item.name}</h6>
                              <p><Moment format="hh:mm">{item.start_timestamp}</Moment> - <Moment format="hh:mm">{item.end_timestamp}</Moment></p>
                            </li>
                          }
                        }) : false
                      }
                      <h6 className="notfound" style={{ padding: 20, marginBottom: 0 }}>No event scheduled on this day.</h6>
                    </ul>
                  </div>
                })
              }
            </Slider>
          </div>
        </div>
      </section>
    );
  }
};

export default Schedule;
