import React, { useEffect, useState } from 'react'
import AudioPlayer from 'react-h5-audio-player';
import $ from 'jquery';
import { Link } from 'react-router-dom';

const Player = () => {

  const [audio, setAudio] = useState(null);
  const [show, setShow] = useState(null);
  const [genre, setGenre] = useState(null);
  const [index, setIndex] = useState(0)
  useEffect(() => {
    const listenStorageChange = () => {
      if (localStorage.getItem("audio_data") === null) {
        setAudio(null);
      } else {
        setAudio(JSON.parse(localStorage.getItem('audio_data')));
        setIndex(0)
      }
      setShow(JSON.parse(localStorage.getItem('audio_current')))
      setGenre(JSON.parse(localStorage.getItem('audio_genre')))
      $('body').addClass('pb-body')
    };
    window.addEventListener("storage", listenStorageChange);
    return () => window.removeEventListener("storage", listenStorageChange);
  }, []);

  useEffect(() => {
    $('.playlist>span').unbind().click(function () {
      $(this).parent().toggleClass('opened');
    })
  })
  const ClosePlayer = () => {
    setAudio(null)
    setIndex(0)
    localStorage.removeItem("audio_data")
    $('body').removeClass('pb-body')
  }

  if (audio == null || audio.length === 0 || show == null || genre == null) {
    return false;
  }
  return (
    <div className='audio-player'>
      <span className='close-palyer' onClick={ClosePlayer}><i class="fa-regular fa-xmark"></i></span>
      <div className='sho-wrp'>
        <img src={localStorage.getItem('audio_image')} alt="images" />
        <div className='show-info'>
          <h5>{show.show_data_name}</h5>
          <p>{show.show_data_description}</p>
          <div className='genres-sho'>
            {
              genre.map((gen) => {
                return <Link to={`/resident/${gen.genre_id}`}>{gen.genre_name}</Link>
              })
            }
          </div>
        </div>
      </div>
      <h4>{audio[index].name}</h4>
      <AudioPlayer
        src={audio[index].file}
        autoPlay
        onPlay={(e) => {
          $("#audio").prop('muted', true);
          $('#audio-control').html('<i class="fas fa-play" aria-hidden="true"></i>');
        }}
        showSkipControls={false}
        showJumpControls={false}
        customAdditionalControls={['']}
        autoPlayAfterSrcChange
      />
      
      {audio.length !== 1 ?
        <div className='playlist'>
          <span>PlayList <i class="fa-regular fa-angle-up"></i></span>
          <div className='collp'>
            {
              audio.map((item, key) => {
                if (index == key) {
                  return <div className='act'><i class="fa-solid fa-circle-pause"></i> {item.name}</div>
                } else {
                  return <div onClick={() => { setIndex(key) }}><i class="fa-solid fa-circle-play"></i> {item.name}</div>
                }
              })
            }
          </div>
        </div> : false}
    </div>
  )
}

export default Player